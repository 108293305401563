import React, { useState, useEffect } from 'react';
import { SendDiagonal,WarningCircle } from 'iconoir-react';
import axios from 'axios' 
import Swal from 'sweetalert2'; import withReactContent from 'sweetalert2-react-content';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import HeaderPage from '../headerPage/HeaderPage'
import Cookies from 'universal-cookie';
import OverlayPopup from '../../general/OverlayPopup';
import { useNavigate } from 'react-router-dom';
import TicketDropdown from '../createTicket/TicketDropdown';
import UploadComp from '../createTicket/UploadComp';
import TicketDropdownShow from '../ticketDetail/TicketDropdownShow';
import OverlaySubmit from '../../general/OverlaySubmit';
import LoadingSpinner from '../../general/LoadingSpinner';
import useOrientation from '../../general/useOrientation';

const cookies = new Cookies();
const MySwal = withReactContent(Swal);
function CreateDocument({refId, changeNavState, isDraft="0"}) {
  const orientation = useOrientation();
  let isThisPortrait = false;
  if (orientation === 'portrait')
    isThisPortrait = true;
  var sessionData = cookies.get('sessionData', { path: '/' })
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [linkCategory, setLinkCategory] = useState('');
  const [refreshDropdown, setRefreshDropdown] = useState(false);
  const [desc, setDesc] = useState('');
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingDraft, setLoadingDraft] = useState(false);
  const [title, setTitle] = useState('');
  const [draftData, setDraftData] = useState({ tiket_number: '-' });
  const [tenantData, setTenantData] = useState({});
  const [popup, setPopup] = useState(false);
  const [popupImageUrl, setPopupImageUrl] = useState("");
  const [oldAttachment, setOldAttachment] = useState('');
  const [marginAttachment, setMarginAttachment] = useState("");
  const [errSubmitText, setErrSubmitText] = useState('');
  const navigate = useNavigate();
  const handleDescChange = (value) => {
    setDesc(value);
    //console.log("data desc",value);
  }

  const handleTitleChange = (e) => {
    setTitle(e.target.value); // Update the state with the new input value
  };
  
  //form variables
  const [dept, setDept] = useState('');
  const [cat, setCat] = useState('');
  const [block, setBlock] = useState('');
  useEffect(() => {
    changeNavState('Document');
    if (isDraft == "1"){
      getDraftData();
    }
    getTenantData();
  }, [])

  const handlePopupClose = () => {
    if (popupImageUrl === "/SuccessMessage.png" || popupImageUrl === "/SuccessDraft.png" || popupImageUrl == "/SuccessMessageDoc.png") {
      navigate("/dashboard")
    } else {
      setPopup(false);
    }
  };

  // Set a maximum size limit for 'desc' including base64-encoded images (in kilobytes)
  const maxDescSizeKB = process.env.REACT_APP_MAX_INLINE_IMAGE_SIZE; // Adjust the limit according to your requirements

  // Function to estimate the size of a base64 string in kilobytes
  const getBase64Size = (base64String) => {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
    const byteSize = base64.length / 4 * 3 - padding.length;
    return byteSize / 1024; // Convert to kilobytes
  };

  const editorStyles = {
    height: '12.5vw',
    width: '100%',
    marginTop: '-0.5vw',
    paddingTop: '1vw',
    fontSize: '1.2vw',
    border: 'none'
  };

  const PickDropdownDept = (val) => {
    setLinkCategory("/docExtCatChild/" + val.value);
    setDept(val.value);
  }
  const PickDropdownCat = (val) => {
    //console.log(val.label);
    setCat(val.value)
  }
  const PickDropdownBlock = (val) => {
    let tmp = "";
    for(let i = 0 ; i < val.length; i++){
      tmp += val[i].value;
      if (i < val.length - 1) {
        tmp += ",";
      }
    }
    setBlock(tmp);
    //console.log(val);
  }

  const getDraftData = () => { 
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    const config = {headers:{['x-access-token']: sessionToken}};
    var dbpath = process.env.REACT_APP_PATH_DESTINATION + "/ticketDraft/"+refId;   
    axios.get(dbpath , config)
      .then(res => { 
          console.log("ticket draft logging",res.data.data);
        if (res.data.status == "200"){
          setDraftData(res.data.data);
          //calc height
          if (res.data.data.attachment){
            setOldAttachment(res.data.data.attachment);
            let attachmentCount = String(res.data.data.attachment).split('|').length;
            let marginTop = `${11 + Math.floor((attachmentCount - 1) / 4) * 11}vw`;
            setMarginAttachment(marginTop);
          } else {
            setMarginAttachment("0");
          }
          setTitle(res.data.data.title);
          setDesc(res.data.data.konten);
        } else {
          //maybe error log / alert here somehow
        }
      })
  }

  const getTenantData = () => { 
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    const config = {headers:{['x-access-token']: sessionToken}};
    var dbpath = process.env.REACT_APP_PATH_DESTINATION + "/docTenantName";   
    axios.get(dbpath , config)
      .then(res => { 
          console.log("docTenantName logging",res.data.data);
        if (res.data.status == "200"){
          setTenantData(res.data.data);
        } else {
          //maybe error log / alert here somehow
        }
      })
  }

  // Callback function to handle file and metadata on change status
  const handleFileChange = (meta, file, status) => {
    if (status === 'done') {
      // File has been successfully uploaded
      setUploadedFiles((prevFiles) => [...prevFiles, { meta, file }]);
    } else if (status === 'removed') {
      // File has been removed
      setUploadedFiles((prevFiles) =>
        prevFiles.filter((uploadedFile) => uploadedFile.file !== file)
      );
    }
    // Add more conditions based on your requirements

    console.log("upload files logging",uploadedFiles);

  };

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['link', 'image'],
    ],
  };

  const CreateDraft = () => { 
    setLoadingDraft(true)
    const descSizeKB = getBase64Size(desc);
    if (descSizeKB > maxDescSizeKB) {
      MySwal.fire({
          title: <strong>Image is too big!</strong>,
          html: <p>Image inside in the body text is too big. Please reduce the image size or use attach file. Max image size is : {maxDescSizeKB} kb </p>
      })
      setLoadingSubmit(false);
      return;
    }
    var sessionToken = cookies.get('sessionToken', { path: '/' });
    const config = { headers: { 'x-access-token': sessionToken } };
    var apiPath = process.env.REACT_APP_PATH_DESTINATION + "/createDraftExt";
  
    // Create a FormData object
    const formData = new FormData();
  
    // Append the uploaded files to the FormData object
    uploadedFiles.forEach((file) => {
      formData.append('files',file.file); // Use the appropriate field name for your API
    });
  
    // Append other data to the FormData object
    //formData.append('deptMgmt', dept);
    formData.append('docCat', cat);
    formData.append('title', title);
    formData.append('konten', desc);
    formData.append('oldAttachment', oldAttachment);
  
    axios.post(apiPath, formData, config)
      .then(res => {
        console.log(res.data);
        if (res.data.status === "200") {
          const now = new Date();
          const utcHour = now.getUTCHours(); // Get current UTC hour
          const gmt7Hour = utcHour + 7; // Convert to GMT+7

          // This is all non-priority document, check business hours
          if (gmt7Hour >= 8 && gmt7Hour < 16) {
            setPopupImageUrl("/SuccessMessage.png");  // Business hours
          } else {
            setPopupImageUrl("/SuccessMessageBHour.png"); // Outside business hours
          }          
          setPopup(true);
          setLinkCategory('');
          setDesc('');
          setTitle('');
          setUploadedFiles([]);
          setRefreshDropdown(!refreshDropdown);
          setLoadingDraft(false)
        } else {
          setPopupImageUrl("/FailMessage.png");
          setPopup(true);
          setLoadingDraft(false)
        }
      })
      .catch(error => {
        console.error("Error uploading files:", error);
        setPopupImageUrl("/FailMessage.png");
        setPopup(true);
        setLoadingDraft(false)
      });
  }

  const handleChangeOldAtch = (value) => {
    setOldAttachment(value);
  }

  function isDescEmpty(desc) {
    // Remove content inside angle brackets
    const strippedContent = desc.replace(/<[^>]*>/g, '').trim();
  
    // Check if the stripped content is empty
    return strippedContent === '';
  }

  const CreateTicket = () => { 
    setLoadingSubmit(true)
    const descSizeKB = getBase64Size(desc);
    if (descSizeKB > maxDescSizeKB) {
      MySwal.fire({
          title: <strong>Image is too big!</strong>,
          html: <p>Image inside in the body text is too big. Please reduce the image size or use attach file. Max image size is : {maxDescSizeKB} kb </p>
      })
      setLoadingSubmit(false);
      return;
    }
    var sessionToken = cookies.get('sessionToken', { path: '/' });
    const config = { headers: { 'x-access-token': sessionToken } };
    var apiPath = process.env.REACT_APP_PATH_DESTINATION + "/createReqDoc";
  
    // Create a FormData object
    const formData = new FormData();
  
    // Append the uploaded files to the FormData object
    uploadedFiles.forEach((file) => {
      formData.append('files',file.file); // Use the appropriate field name for your API
    });
  
    // Append other data to the FormData object
    //formData.append('deptMgmt', dept);
    formData.append('docCat', cat);
    formData.append('block', block);
    formData.append('title', title);
    formData.append('konten', desc);
    formData.append('oldAttachment', oldAttachment);
  
    var emptyString = "";

    if (cat == ''){
      if (emptyString != ''){emptyString += ", "}
      emptyString += "Category";
    }
    if (title == ''){
      if (emptyString != ''){emptyString += ", "}
      emptyString += "Subject";
    }
    if (desc == '' || isDescEmpty(desc)){
      if (emptyString != ''){emptyString += ", "}
      emptyString += "Description";
    }
    if (block == ''){
      if (sessionData.int_ext == 2){
        if (emptyString != ''){emptyString += ", "}
        emptyString += "Blocks";
      }
    }
    if (emptyString !== "") {
      emptyString += " cannot be empty." 
      setErrSubmitText(emptyString);
      setLoadingSubmit(false)
      return
    }

    axios.post(apiPath, formData, config)
      .then(res => {
        console.log(res.data);
        if (res.data.status === "200") {
          setPopupImageUrl("/SuccessMessageDoc.png");
          setPopup(true);
          setLinkCategory('');
          setDesc('');
          setTitle('');
          setUploadedFiles([]);
          setRefreshDropdown(!refreshDropdown);
          setLoadingSubmit(false)
        } else {
          setPopupImageUrl("/FailMessage.png");
          setPopup(true);
          setLoadingSubmit(false)
        }
      })
      .catch(error => {
        console.error("Error uploading files:", error);
        setPopupImageUrl("/FailMessage.png");
        setPopup(true);
        setLoadingSubmit(false)
      });
  }
  
  if (orientation === 'landscape')
  return (
  <>
  {popup && (
    <OverlayPopup
      imageUrl={popupImageUrl}
      message={popupImageUrl}
      onClose={handlePopupClose}
    />
  )}
 {(loadingSubmit || loadingDraft) && (
    <OverlaySubmit
      imageUrl={popupImageUrl}
      message={popupImageUrl}
      onClose={handlePopupClose}
    />
  )}
    <HeaderPage 
        title1={"Document"}
        link1={"/documentList"}
        title2={"Request Document"}
    />

<div className="headerPageMarginTop">
  <section className="content" style={{ marginLeft: "3vw", position: 'relative' }}>
    <div className="container-fluid" style={{ position: 'relative' }}>
      <div className='row mx-auto text-center' style={{}}>
        
        <div className="col-md-3 text-left h-100" style={{ width: '100%', position: 'relative' }}>
          <div style={{ marginLeft: '-3vw' }}>
           
            {isDraft == "0" && ( 
              <>
                <TicketDropdown type={4} pickDropdown={PickDropdownDept} title="Category" link='/docExtCatParent' refresh={refreshDropdown} />
                <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
                <TicketDropdown type={2} pickDropdown={PickDropdownCat} title="Sub Category" link={linkCategory} refresh={refreshDropdown} />
                <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
                <TicketDropdownShow title="Tenant" value={tenantData.tenant_id} label={tenantData.nama_tenant} />
                <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
                <TicketDropdown type={5} pickDropdown={PickDropdownBlock} title="Blocks" link='/docTenantBlok' refresh={refreshDropdown} isMulti={true}/>
              </>
            )}
            {isDraft == "1" && ( 
              <>
                <TicketDropdown type={4} pickDropdown={PickDropdownDept} title="Category" link='/docExtCatParent' refresh={refreshDropdown} defValue={draftData.parent_name_id}  setRefresh={setRefreshDropdown} />
                <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
                <TicketDropdown type={2} pickDropdown={PickDropdownCat} title="Sub Category" link={linkCategory} refresh={refreshDropdown} defValue={draftData.ext_kategory_id} setRefresh={setRefreshDropdown}/>
                <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
                <TicketDropdownShow title="Tenant" value={tenantData.tenant_id} label={tenantData.nama_tenant} />
                <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
                <TicketDropdown type={5} pickDropdown={PickDropdownBlock} title="Blocks" link='/docTenantBlok' refresh={refreshDropdown} isMulti={true} />
              </>
            )}
            
          </div>
          <div>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/><br/>

                <br/>
          </div>
        </div>
        
        <div className="col-md-1 h-100">
        <div style={{ marginLeft: '1.5vw', position: 'absolute', left: 0, top: '-2.01vw', bottom: 0, borderLeft: '1px solid #E8E9EB', height: '110vh' }}></div>
        </div>

        <div className="col-md-8 text-left h-100" style={{ width: '100%', marginLeft:'-3vw'}}>
          <div className='borderWithRadius h-100' style={{ paddingBottom:'4vw'}}>
            <div style={{ marginLeft: '2vw', marginTop: '1.5vw', marginRight: '2vw' }}>
              <h5 style={{ fontSize: '2.5vw', fontFamily: 'Blender Pro' }}> Request Document </h5>

              <p className="txtCreateTicket" style={{ marginTop: '2vw' }}>Subject</p>
              <input
                placeholder='Input Fields'
                className='inputText'
                type='text'
                style={{ marginTop: '-2.5vw' }}
                value={title}
                onChange={handleTitleChange}
              />
              <br />
              <br />
              <p className="txtCreateTicket">Description</p>
              <div>
                <ReactQuill
                  value={desc}
                  onChange={handleDescChange}
                  modules={modules}
                  className='inputText'
                  style={{minHeight:'10vw'}}
                />
              </div>
              <br/> <br/>
              <UploadComp onChangeStatus={handleFileChange} refresh={refreshDropdown} atch={oldAttachment} setAtchString={handleChangeOldAtch} isDraft={isDraft}/>
              
            </div>
            <br/><br/>
            {errSubmitText && (
              <div style={{marginLeft: '2vw',marginTop:'-3vw',marginBottom:'3vw'}}>
                <p style={{fontSize:'1.2vw'}}>
                <WarningCircle width="16px" color="red" />
                  &nbsp;&nbsp;&nbsp;
                  <font color="red">{errSubmitText}</font>
                </p>
              </div>
            )}
            <div style={{float:'right', marginRight:'2vw',marginTop: marginAttachment}}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <button className="btn  btnSubmitTicket" type="button" onClick={CreateTicket}>
                {loadingSubmit ? (
                  <LoadingSpinner /> 
                ) : (
                  <SendDiagonal /> 
                )} &nbsp; Submit Ticket
              </button>
              <br/>
              &nbsp;
              </div>
            </div>
        </div>
      </div>
    </div>
  </section>
</div>

  </>
  )
  
  if (orientation === 'portrait')
  return (
  <>
  {popup && (
    <OverlayPopup
      imageUrl={popupImageUrl}
      message={popupImageUrl}
      onClose={handlePopupClose}
    />
  )}

  {(loadingSubmit || loadingDraft) && (
    <OverlaySubmit
      imageUrl={popupImageUrl}
      message={popupImageUrl}
      onClose={handlePopupClose}
    />
  )}
    <HeaderPage 
        title1={"Ticket"}
        link1={"/ticketList"}
        title2={"Create Ticket"}
    />

<div className="headerPageMarginTop" style={{width:'100%'}}>
  <section className="content" style={{ marginLeft: "3%", position: 'relative' }}>
    <div className="container-fluid" style={{ position: 'relative' }}>
      <div className='row mx-auto text-center' style={{}}> 
        
        <div className="text-left h-100" style={{ width: '93%', position: 'relative' }}>
        <h5 style={{ marginBottom:'20px', fontSize: '20px', fontFamily: 'Blender Pro' }}> Create Ticket </h5> 
          <div style={{ marginLeft: '0vw' }}>
          {isDraft == "0" && ( 
              <>
                <TicketDropdown type={4} pickDropdown={PickDropdownDept} title="Category" link='/docExtCatParent' refresh={refreshDropdown} />
                <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
                <TicketDropdown type={2} pickDropdown={PickDropdownCat} title="Sub Category" link={linkCategory} refresh={refreshDropdown} />
                <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
                <TicketDropdownShow title="Tenant" value={tenantData.tenant_id} label={tenantData.nama_tenant} />
                <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
                <TicketDropdown type={5} pickDropdown={PickDropdownBlock} title="Blocks" link='/docTenantBlok' refresh={refreshDropdown} isMulti={true}/>
              </>
            )}
            {isDraft == "1" && ( 
              <>
                <TicketDropdown type={4} pickDropdown={PickDropdownDept} title="Category" link='/docExtCatParent' refresh={refreshDropdown} defValue={draftData.parent_name_id}  setRefresh={setRefreshDropdown} />
                <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
                <TicketDropdown type={2} pickDropdown={PickDropdownCat} title="Sub Category" link={linkCategory} refresh={refreshDropdown} defValue={draftData.ext_kategory_id} setRefresh={setRefreshDropdown}/>
                <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
                <TicketDropdownShow title="Tenant" value={tenantData.tenant_id} label={tenantData.nama_tenant} />
                <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
                <TicketDropdown type={5} pickDropdown={PickDropdownBlock} title="Blocks" link='/docTenantBlok' refresh={refreshDropdown} isMulti={true} />
              </>
            )}
            
          </div>
          
        </div>
        

        <div className="col-md-8 text-left" style={{ width: '100%', marginLeft:'-3vw'}}>
          <div className='' style={{ paddingBottom:'4vw'}}>
            <div style={{ marginLeft: '2vw', marginTop: '1.5vw', marginRight: '2vw' }}>
                         
              <p className="txtCreateTicketMobile" style={{ marginTop: '2vw' }}>Subject</p>
              <input
                placeholder='Input Fields'
                className='inputTextMobile'
                type='text'
                style={{ marginTop: '-2.5vw' }}
                value={title}
                onChange={handleTitleChange}
              />
              <br />
              <br />
              <p className="txtCreateTicketMobile">Description</p>
              <div>
                <ReactQuill
                  value={desc}
                  onChange={handleDescChange}
                  modules={modules}
                  className='inputText'
                  style={{minHeight:'150px'}}
                />
              </div>
              <br />
              <UploadComp onChangeStatus={handleFileChange} refresh={refreshDropdown} atch={oldAttachment} setAtchString={handleChangeOldAtch} isDraft={isDraft}/>
            </div>
            
            <br /><br /><br /><br />
            {errSubmitText && (
              <div style={{marginLeft: '2vw',marginTop:'-3vw',marginBottom:'3vw'}}>
                <p style={{fontSize:'15px'}}>
                <WarningCircle width="16px" color="red" />
                  &nbsp;&nbsp;&nbsp;
                  <font color="red">{errSubmitText}</font>
                </p>
              </div>
            )}
            <div style={{float:'right', marginRight:'2vw',marginTop: marginAttachment}}>
              
              <a className="draftClass cursorPointer" style={{fontSize:'15px'}} onClick={CreateDraft}>
                {loadingDraft && (
                  <LoadingSpinner />  
                )} &nbsp; Save as draft
              </a>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <button className="btn btnSubmitTicketMobile" type="button" onClick={CreateTicket}>
                {loadingSubmit ? (
                  <LoadingSpinner /> 
                ) : (
                  <SendDiagonal /> 
                )} Submit Ticket
              </button>
              <br/>
              &nbsp;
              </div>
            </div>
        </div>
        
      </div>
    </div>
  </section>
</div>

  </>
  )
}

export default CreateDocument